.team {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 15px;
}

.member-card { 
    padding: 10px;
    border-radius: 20px;
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.45) !important;
    height: 325px;
    width: 260px;
    margin: 15px;
    box-shadow: 0px 0px 20px -15px #000000f0;
}

.member-name{
    font-size: clamp(17px, 6vw, 23px);
    margin-top: 12px;
} 

.profile-pic {
    width: 45%;
    border-radius: 25px;
    margin-top: 5px;
    margin-bottom: -5px;
}

.data-section {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.bio-line-list {
    text-align: left;
    font-size: 14px;
    padding-left: 15px;
}

.bio-line {
    font-weight: 500;
}

.team-container {
    padding-bottom: 30px;
}

#to-work-samples-button {
    text-decoration: none;
    background: #bb8019;
    color: white;
    text-shadow: 0px 0px 4px black;
    box-shadow: 0 0 13px -4px black;
    font-size: clamp(11px, 4vw, 17px);
    padding: 10px 14px;
    border-radius: 5px;
    font-weight: 500;
    transition: background 0.15s;
}

#to-work-samples-button:hover {
    background: #dfbc52;
}