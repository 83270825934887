.navbar {
    background: #98938de6 !important;
    box-shadow: 0px 0px 12px -2px #000000f0;
}

.navbar-brand {
    margin-left: 5px;
    font-size: clamp(15px, 5vw, 21px) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    font-weight: 500;
    bottom: 0.5px;
    position: relative;
}

.navbar-toggler {
    margin-right: 0 !important;
}

.offcanvas-header .btn-close {
    margin-right: 2px !important;
    right: 15px;
    top: 19px;
    position: absolute;
    font-size: 22px;
}

.logo {
    width: 27px;
    height: 27px;
    margin: 0px 3px;
}

.dropdown-menu {
    right: 0 !important;
    left: auto !important;
}

.nav-link {
    color: #dadada !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    margin-right: 9px;
    padding: 5px 10px !important;
    border-radius: 6px;
}
.nav-link:hover {
    color: white !important;
    background: #888888bd;
}
.nav-link.active {
    color: white !important;
    text-decoration: underline;
}

.modal-open .nav-link {
    color: black !important;
    margin-right: 0px;
    background: transparent;
}
.modal-open #dropdown-basic-button {
    margin-top: 10px;
    margin-left: 0;
    background: #98938d;
}
.modal-open #dropdown-basic-button:hover {
    background: #888888;
}
.modal-open .nav-link:hover {
    color: gray !important;
}

.navbar-toggler {
    border-color: white !important;
    padding: 4px 6px !important;
}
