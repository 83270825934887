.footer-list {
    list-style-type: none; /* Remove bullets */
    padding-left: 0 !important;
}

.footer {
    background-color: #98938d;
    color: #fff;
    padding: 3rem 0;
    text-align: left;
    box-shadow: 0px -2px 15px -5px #000000f0;
    margin-top: auto;
}

.footer .container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    max-width: 720px;
    padding-left: 2px !important;
    padding-left: 2px !important;
}

.footer .copyright {
    font-size: 0.8rem;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0 10px;
}

.footer .footer-nav {
    display: flex;
}

.footer .footer-nav a {
    color: #fff;
    text-decoration: none;
    margin-left: 1rem;
}

.footer .footer-nav a:hover {
    color: #ccc;
}

.list-container {
    margin: 0 2px;
    width: 140px;
}

.footer a {
    color: gainsboro;
    font-size: 14px;
}
.footer a:hover {
    color: white;
}

.footer-section-title {
    font-size: 16px;
}

#trademark-container {
    width: 255px;
    display: block;
    margin-top: 9px;
    margin-left: auto;
    margin-right: auto;
}
#inhinito-trademark {
    width: 100%;
}

