.carousel {
    margin-top: clamp(15px, 4.5vw, 34px);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
    box-shadow: 0px 0px 25px -15px #000000f0;
    border-radius: 30px;
}
#first-slide {
    cursor: pointer;
}

.carousel-caption {
    background: #ffffffeb;
    padding-top: 5px !important;
    padding-bottom: 15px !important;
    margin: -10px -5px !important;
    border-radius: 12px;
}
.carousel-text {
    font-weight: 500;
    padding: 7px;
    font-size: clamp(17px, 3vw, 22px);
}

.carousel-photo {
    width: 100%;
    max-height: 425px;
    height: 70vh;
    border-radius: 30px;
    object-fit: cover;
}

.carousel-control-next, .carousel-control-prev {
    background: #ffffff4f !important;
    width: 12% !important;
}
.carousel-control-prev {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(187,128,25,0) 85%) !important;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
.carousel-control-next {
    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(187,128,25,0) 85%) !important;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

form {
    width: 90%;
    max-width: 900px;
    scroll-margin: 20px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
}

.form-checkbox {
    left: 48px;
    width: 22px !important;
    height: 22px;
    margin-top: 27px;
    transform: scale(2.3);
    margin-left: -21px;
    z-index: 1;
    top: 0;
    position: absolute;
    cursor: pointer;
}

.form-checkbox:focus { outline: none; }


.form-label {
    cursor: pointer;
    padding: 15px;
    width: 180px;
    height: 180px;
    font-weight: 600;
    margin-bottom: 0 !important;
    box-shadow: 0px 5px 10px -5px #525252;

    display: flex;
    flex-direction: column;
    justify-content: center;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.form-section {
    scroll-margin: 20px;
    margin-bottom: clamp(35px, 4.5vw, 50px);
    background: #f8f8f8;
    border-radius: 20px;
    box-shadow: 0px 0px 25px -15px #000000f0;
}

#custom-order .form-section {
    padding-bottom: 25px;
}

#custom-order .letter-input-label {
    margin-top: 15px;
    font-size: 20px;
}

.option-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 0;
}

.option-block {
    margin: 15px;
    display: flex;
}


.popover {
    z-index: 5 !important;
    box-shadow: 0 0 15px -5px #363636 !important;
}

.popover-header {
    font-family: 'Manrope';
    font-weight: bold;
    color: #96694c !important;
    background-color: white !important;
    padding-left: 14px !important;
}

.popover-body {
    padding: 6px 6px 10px 13px !important;
    font-family: 'Manrope';
    width: 215px;
    color: #2b2b2b !important;
}

.popover-image {
    width: 100%;
    margin-left: -2px;
    padding-right: 4px;
    margin-top: 4px;
    cursor: pointer;
}

.popover-overlay {
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 36px;
    background: white;
    padding: 4px;
    border: 2px solid black;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: #d8a420 -6px -5px 10px -5px;
    animation: zoom-in-zoom-out 2s ease infinite;
}

.directions {
    background: #ffffffa3;
    padding: 5px;
    margin: 0 10px -5px;
    border-radius: 10px;
    border: 3px solid #d6c182;
    font-size: clamp(12px, 2.5vw, 15px);
}

#video-order {
    background: rgba(170, 197, 255, .45);
}

#photography-order {
    background: rgba(206, 181, 230, .45);
}

.typical-order-header {
    color: white;
    font-size: clamp(21px, 4vw, 28px);
    text-shadow: 0px 0px 5px #000000;
    border-radius: 10px;
    padding: 8px;
    margin-bottom: 20px;
    position: sticky;
    top: 20px;
    z-index: 3;
    box-shadow: 0px 4px 20px -2px #000000f0;
    line-height: 38px;
    
    background: #d6c182;
    /* background: linear-gradient(38deg, rgba(255,213,109,1) 0%, rgba(187,128,25,1) 70%); */
}

.typical-group {
    padding: 15px;
    border-radius: 20px;
    margin-bottom: clamp(35px, 4.5vw, 50px);
}

.typical-group .form-section:last-child {
    margin-bottom: 0;
}

.info-button-icon {
    border: 2px solid #d6c182;
    border-radius: 20px;
    padding: 1px 12px;
    color: red;
    font-weight: bold;
    box-shadow: black 0 0 9px -4px;
    transition: background 200ms;
}

.info-button {
    border-radius: 25px !important;
    z-index: 1;
    position: absolute;
    right: 13px;
    margin-top: 10px;
    font-size: 25px !important;
    border: 2px solid gray !important;
    padding: 4px 3px !important;
    background: white !important;

    will-change: transform;
    /* transform: translateY(-4px); */
    /* transition: transform 100ms cubic-bezier(.3, .7, .4, 1); */
    box-shadow: black 0px 2px 14px -6px;
}
.info-button:hover .info-button-icon {
    border-color: #d1a946 !important;
}
.info-button:active {
    transform: translateY(-1px);
    transition: transform 100ms cubic-bezier(.3, .7, .4, 1.5);
}
.info-button:active .info-button-icon {
    background: #ffeed9 !important;
}


.pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    color: white;
}
.shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}
.edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient( 
        to left,
        #414141 0%,
        #767264 8%,
        #767264 92%,
        #414141 100%
    );

}

.front {
    border-radius: 12px;
    font-size: 1.25rem;
    background: #98938de6;

    background-size: cover !important;
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}

/* 
label[for="wedding"] {
    background: url("/public/media/images/buttons/wedding.jpg");
}
label[for="baptism"] {
    background: url("/public/media/images/buttons/baptism.jpg");
}
label[for="custom-order"] {
    background: url("/public/media/images/buttons/custom.jpg");
} 
*/


/* Services images */
label[for="video"] {
    background: url("/public/media/images/buttons/video.jpg");
}
label[for="photography"] {
    background: url("/public/media/images/buttons/photography.jpg");
}

/* Album images 
label[for="photography-sparse"] {
    background: url("/public/media/images/album/templates/Sparse.png");
}
*/

.pushable-active:hover {
    filter: brightness(110%);
}
.pushable-active:hover .front {
    transform: translateY(-6px);
    transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}
.pushable-active:hover .shadow {
    transform: translateY(4px);
    transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}

/* Active button styles */
/* .pushable-active:active:not(:focus-within) .front { */
.pushable-active:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
}
.pushable-active:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
}


.pushable:focus:not(:focus-visible) {
    outline: none;
}

.label-center {
    text-shadow: 0px 0px 5px #000000;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 8px;
    font-size: 17px;
    padding: 0 8px;
    line-height: 24px;
    font-weight: 500;
}

.label-bottom {
    bottom: 18px;
    position: absolute;
    left: 0;
    right: 0;
    font-weight: 400;
    background: goldenrod;
}


.box {
    width: 400px;
    height: 600px;
    margin: 50px auto;
    background: #fff;
    border-radius: 3px;
}



input[type="submit"] {
    margin-top: 30px;
}

#description  {
    height: 15em;
    width: 90%;
    max-width: 685px;
    margin: 0 auto !important;
}

#example-urls {
    width: 90%;
    max-width: 685px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
    

.close-button {
    position: absolute;
    right: 8px;
    top: 6px;
}


/* Remove numeric input arrows in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#price-input {
    /* Remove numeric input arrows in Firefox */
    -moz-appearance: textfield;

    pointer-events: none;
    width: 50px;
    height: 25px;
    padding-right: 5px;
    border: none;
    border-radius: 5px;
    height: 25px;
    text-align: right;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.ribbon {
    margin: -11px;
    display: block;
    width: calc(100% + 42px);
    height: 35px;
    line-height: 33px;
    text-align: center;
    margin-left: -21px;
    position: relative;
    top: 54px;
    font-size: 17px;
    font-weight: 400;
    text-shadow: 0px 0px 5px #000000;
    box-shadow: black 0px 1px 20px -10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    background: #d6c182;
}
.ribbon:before, .ribbon:after {
    content: "";
    position: absolute;
}
.ribbon:before {
    height: 0;
    width: 0;
    bottom: -9.7px;
    left: 0;
    border-top: 10px solid #ca8f81;
    border-left: 6px solid transparent;
}
.ribbon:after {
    height: 0;
    width: 0;
    right: 0.3px;
    bottom: -9.7px;
    border-top: 10px solid #ca8f81;
    border-right: 6px solid transparent;
}
  
.letter-input-container {
    padding: 20px 20px 0;
}
.letter-input-container .form-control {
    display: block;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
}
.letter-input-container:last-child {
    padding-bottom: 20px;
}

.letter-input-label {
    margin: 0px 7px 5px;
    font-weight: 500;
}

.letter-input {
    padding: 4px;
    display: inline-block;
    min-width: 200px;
    text-align: center;
    box-shadow: 0 0 5px -4px;
}

#mailing-list {
    transform: scale(2.7);
    display: block;
    margin-left: auto;
    margin-right: auto;
    transform-style: preserve-3d;
    margin-top: 24px;
    margin-bottom: 30px;
    right: 66px;
    position: relative;
    cursor: pointer;
    --button-content: "Exclusive offers"; /* Setting this with JS in ContactInfo.js */

}
#mailing-list::before {
    content: var(--button-content);
    text-align: center;
    display: block;
    font-size: 6px;
    width: 70px;
    height: 21px;
    position: absolute;
    margin-top: -4px;
    left: -4px;
    padding-left: 21px;
    padding-right: 5px;
    padding-bottom: 21px;
    line-height: 21px;
    background: white;
    border-radius: 3px;
    transform: translateZ(-1px);
    box-shadow: 0 0 5px -4px;
}

#date {
    padding-left: 29px;
}


.spinner-border {
    padding: 20px !important;
    margin: 20px 0;
}

.submission-icon {
    display: block;
    margin: 20px auto 10px;
    width: 90px;
}


