
#gallery {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;

    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;

    padding: 10px 20px;
}
@media (max-width: 1200px) {
  #gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;

  }
}
@media (max-width: 800px) {
  #gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;

  }
  .album-samples {
    margin: -10px 0 -3px !important;
  }
  .album-sample {
    margin: 17px 0 !important;
  }
}
@media (max-width: 600px) {
  form {
    margin-top: clamp(15px, 4.5vw, 50px) !important;
  }

  .carousel-photo {
    height: 250px;
  }

  #gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  
  #gallery img {
    pointer-events: none;
  }
}

#gallery img {
    margin: 10px auto;
}
#gallery img {
    margin: 10px auto;
}
#gallery video {
    margin-bottom: 3px;
    margin-top: 9px;
    position: relative;
}
.media-button {
  width: 100%;
  height: auto;
  box-shadow: 0px 0px 15px -5px #000;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 10px;
  transition: all .2s ease-in-out;
}
.media-button:hover { transform: scale(1.03); }

.modal-title {
  margin-left: auto;
  margin-right: -7px;
  font-size: clamp(19px, 3vw, 25px);
}
.modal-content {
  width: 100%;
  height: auto;
}

// .modal-dialog {
//   max-width: 91% !important; 
// }


/* #gallery img:first-child, #gallery video:first-child {
    margin-top: 0 !important;
} */

.album-samples {
  margin: -23px 0 -15px;
}

.album-sample {
  width: 100%;
  padding: 0 6px;
  margin: 30px 0;
}