@font-face {
    font-family: 'Manrope';
    src: url('../public/fonts/Manrope-Regular.ttf')
}

html, body {
    text-align: center;
    font-family: 'Manrope', sans-serif !important;
    background: white;
}


.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header-block {
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, .45) !important;
    color: #484848;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 12px 5px 1px;
}

.header-block h5 {
    box-shadow: 0px 0px 15px -11px #747474;
}

.main-container {
    background: rgba(222, 226, 230, 0.5);
    width: 90%;
    max-width: 900px;
    margin-top: clamp(15px, 4.5vw, 50px);
    margin-bottom: clamp(15px, 4.5vw, 50px);
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    box-shadow: 0px 0px 25px -15px #000000f0;
}

.critical-tip {
    z-index: 3 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

h2 {
    font-size: clamp(16px, 4vw, 23px) !important;
    /* font-weight: 600 !important; */
    margin-bottom: 0px;
    padding: 0px 4px;
    color: #96694c !important;
}

h5 {
    font-size: clamp(13px, 3vw, 18.5px) !important;
    margin-top: 5px !important;
    color: #817e7b !important;
    margin-bottom: 15px !important;
    width: fit-content;
    margin: auto;
    border-radius: 5px;
    padding: 5px 8px;
    background: white;
}

h6 {
    font-size: 18px !important;
}

#price-container {
    width: 95%;
    max-width: 720px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
    
    bottom: 20px !important;
    padding: 8px 14px;
    position: sticky;
    z-index: 6;
    border-radius: 50px;
    color: white;
    text-shadow: 0px 0px 3px #000000;
    font-size: clamp(14px, 2.4vw, 17px);
    box-shadow: 0px 0px 10px 0px #fffffff0;
    background: #363636;
    backdrop-filter: blur(8px);
}

#price-tag {
    font-weight: bolder;
    color: white;
    padding: 4px 7px;
    border: 2px solid;
    border-radius: 8px;
    margin-left: 3px;
    position: relative;
    box-shadow: 0 0 10px -3px white;
    position: relative;
    top: 0.5px;
    transition: all 0.5s;
    scale: 1;
    display: inline-block;
}

.price-tab-link {
    color: white;
    text-shadow: none !important;
}
.price-tab-link:hover {
    color: #d6c182;
}

#order-submit {
    margin-bottom: clamp(35px, 4.5vw, 50px);
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e3e8f4;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: goldenrod;
    background: linear-gradient(38deg, #d6c182 0%, #bb8019 115%);
    /* border: 2px solid #c3d1ef; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ffc45d;
}

.tooltip-inner {
    font-family: 'Manrope', sans-serif !important;
    font-weight: bold !important;
    letter-spacing: 0.6px !important;
}

.gold {
    background: goldenrod;
    background: linear-gradient(38deg, #ffca46 0%, #bb8019 115%)
}

.language-group {
    position: absolute;
    top: 59px;
    left: 0;
    right: 0;
}

.language-option {
    display: inline-block;
    background: white;
    margin: 6px 3px;
    padding: 5px 10px;
    border-radius: 8px;
}

#dropdown-basic-button {
    position: relative;
    background: transparent;
    border: 1px solid white;
    font-weight: 500;
    border: none;
    margin-left: 4px;
}

#dropdown-basic-button:hover {
    background: #888888bd;
}

.pulse {
    animation: pulse-animation 2s infinite, zoom-in-zoom-out 2s ease infinite;
    animation-delay: 0s, 1s;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(225,225,225, 0.5);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(225,225,225, 0);
    }
}

@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    10% {
        transform: scale(1, 1);
    }
    50% {
      transform: scale(1.15, 1.15);
    }
    90% {
        transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
}


/* Cookie consent banner styles */
.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f5f5f5;
    padding: 1rem;
    text-align: center;
    z-index: 1000;
    box-shadow: black 0px 40px 120px 50px;
}
  
.cookie-consent-banner p {
    margin: 0;
    padding: 0.5rem 0;
    font-size: 14px;
}
  
.cookie-consent-banner__buttons {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
}
  
.cookie-consent-banner__buttons button {
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 10px;
    letter-spacing: 1px;
}
.cookie-consent-banner__buttons button:hover {
    background-color: #d6c182 !important;
}
#yes-button {
    background-color: #d3aa44;
    color: white;
    font-weight: bold;
}

  

.policy-content {
    padding: 20px;
    text-align: left;
}

.quote-block {
    margin-top: 10px;
    margin-left: 20px;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
  
td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

@media (min-width: 600px) {

    #price-tab-step-1 {
        padding: 0 40px;
        display: block;
    }
    
    // Scroll down arrow animation
    #left-arrow {
        left: 2%;
    }
    #right-arrow {
        right: 2%;
    }
    
    .scroll-down-arrow {
        background-image: url("../public/media/images/icons/arrow-down.svg");
        background-size: contain;
        background-repeat: no-repeat;
    }
      
    .scroll-down-link {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: 35px;
        width: 35px;
        color: #FFF;
        text-align: center;
        text-decoration: none;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
        -webkit-animation: fade_move_down 2s ease-in-out infinite;
        -moz-animation:    fade_move_down 2s ease-in-out infinite;
        animation:         fade_move_down 2s ease-in-out infinite;
        /*animated scroll arrow animation*/
        @-webkit-keyframes fade_move_down {
            0%   { -webkit-transform:translate(0,-5px); opacity: 0;  }
            50%  { opacity: 1;  }
            100% { -webkit-transform:translate(0,5px); opacity: 0; }
        }
        @-moz-keyframes fade_move_down {
            0%   { -moz-transform:translate(0,-5px); opacity: 0;  }
            50%  { opacity: 1;  }
            100% { -moz-transform:translate(0,5px); opacity: 0; }
        }
        @keyframes fade_move_down {
            0%   { transform:translate(0,-5px); opacity: 0;  }
            50%  { opacity: 1;  }
            100% { transform:translate(0,5px); opacity: 0; }
        }
    }
}